import Image from '../Image/Image';

import styles from './ImageLink.module.scss';

const ImageLink = ({ image = {}, link = {} }) => {
    return (
        <div className={styles['ImageLink']}>
            <a
                className={styles['ImageLink__Link']}
                href={link.href}
                target={link.target}>
                <span className={'sr-only'}>{link.title}</span>
            </a>
            <h2 className={styles['ImageLink__Title']} aria-hidden>
                {link.title}
            </h2>
            <figure className={styles['ImageLink__Image']}>
                <Image {...{ image }} sizes={'(min-width: 1800px) 828px'} />
            </figure>
        </div>
    );
};

ImageLink.propTypes = {};

export default ImageLink;
