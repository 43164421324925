// import PropTypes from 'prop-types';
import ImageLink from '../ImageLink/ImageLink';
import styles from './ImageLinkList.module.scss';

const ImageLinkList = ({ items = [] }) => {
    if (!items.length) {
        return;
    }

    return (
        <div className={styles['ImageLinkList']}>
            <div className={styles['ImageLinkList__Container']}>
                <div className={styles['ImageLinkList__Grid']}>
                    {items.map((item, index) => (
                        <ImageLink key={index} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

ImageLinkList.propTypes = {};

export default ImageLinkList;
